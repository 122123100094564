<template>
    <div>
        <b-container>
        <h1>TERMS OF SERVICE</h1>
        <p>Effective Date: 19th August, 2021</p>
        <p>The terms of this agreement ("Terms of Service") govern the relationship between you and Han Book Pte. Ltd., a Singaporean company (hereinafter "JoyHunter" or "Us" or "We") regarding your use of JoyHunter's games, websites and related services (the "Service"). Use of the Service is also governed by JoyHunter's Privacy Policy and other relevant policies, which are incorporated herein by reference.</p>
        <p>Before accessing or using the Service, including browsing any JoyHunter’s website or accessing a game, you must agree to these Terms of Service and the Privacy Policy. You may also be required to register an account on the Service (an "Account"). By registering for an Account or otherwise using the Service, you represent that you are age 13 or older. If you are between the ages of 13 and 17, you represent that your legal guardian has reviewed and agreed to these Terms. If you access the Service from a Social Networking Site ("SNS"), such as Facebook or Google+, you shall comply with its terms of service/use as well as these Terms of Service.</p>
        <p>BY INSTALLING, USING OR OTHERWISE ACCESSING THE SERVICE, YOU AGREE TO THESE TERMS OF SERVICE. IF YOU DO NOT AGREE TO THESE TERMS OF SERVICE, PLEASE DO NOT INSTALL, USE OR OTHERWISE ACCESS THE SERVICE. USE OF THE SERVICE IS VOID WHERE PROHIBITED.</p>
        <p>JoyHunter reserves the right, at its discretion, to change, modify, add or remove portions of these Terms of Service, its Privacy Policy and other relevant  policies at any time by posting the amended terms on the  Service. You will be deemed to have accepted such changes by continuing to use the Service. If at any point you do not agree to any portion of the then-current version of our Terms of Service, the JoyHunter’s Privacy Policy, or any other  policies, rules or codes of conduct relating to your use of the Service, your license to use the Service shall immediately terminate, and you must immediately stop using the Service.</p>

        <h5>1. License</h5>
        <h6>1.1. Grant of a Limited License to Use the Service</h6>
        <p>Subject to your agreement and continuing compliance with these Terms of Service and any other relevant JoyHunter policies, JoyHunter grants you a non-exclusive, non-transferable, non-sublicensable, revocable and limited license to access and use the Service for your own non-commercial entertainment purposes. You agree not to use the Service for any other purpose.</p>
        <p>The following restrictions apply to the use of the Service:</p>
        <ul>
            <li>
                You shall not create an Account or access the Service if you are under the age of 13; You shall restrict use by minors, and you will deny access to children under the age of 13. You accept full responsibility for any unauthorized use of the Service by minors. You are responsible for any use of your credit card or other payment instrument (e.g. paypal) by minors.
            </li>
            <li>
                You shall not (or attempt to) purchase, sell, rent or give away your Account, create an Account using a false identity or information, or on behalf of someone other than yourself; You shall not use the Service if you have previously been removed by JoyHunter, or previously been banned from playing any JoyHunter game.
            </li>
            <li>
                You shall use your Account only for non-commercial purposes; You shall not use the Service to advertise, or solicit, or transmit any commercial advertisements, including chain letters, junk or spam e-mail or repetitive or misleading messages to anyone.
            </li>
        </ul>
        <h5>Login information and Your Account</h5>
        <p>
            You may be required to select a password for your Account or you may also use other credentials to access the Account ("Login Information"). You shall not share the Account or the Login Information, nor let anyone else access your Account or do anything else that might jeopardize the security of your Account. In the event you become aware of or reasonably suspect any breach of security, including without limitation any loss, theft, or unauthorized disclosure of the Login Information, you must immediately notify JoyHunter and modify your Login Information. You are solely responsible for maintaining the confidentiality of the Login Information, and you will be responsible for all uses of the Login Information, including purchases, whether or not authorized by you. You are responsible for anything that happens through your Account.
        </p>
        <p>
            JoyHunter reserves the right to remove or reclaim any usernames at any time and for any reason, including but not limited to claims by a third party that a username violates the third party's rights.
        </p>
        <p>
            The Service supports only one Account per game on a supported device.
        </p>
        
        <h5>
            License Limitations
        </h5>
        <p>
            Any use of the Service in violation of these License Limitations is strictly prohibited, can result in the immediate revocation of your limited license and may subject you to liability for violations of law.
        </p>
        <p>You agree that you will not, under any circumstances:</p>
        <p>
            Engage in any act that JoyHunter deems to be in conflict with the spirit or intent of the Service or make improper use of JoyHunter's support services.
            Use or take part (directly or indirectly) in the use of cheats, exploits, automation software, bots, hacks, mods or any unauthorized third-party software designed to modify or interfere with the Service, any JoyHunter game or any JoyHunter game experience.
            Modify or cause to be modified any files that are a part of the Service or any JoyHunter game without JoyHunter's express written consent. Disrupt, interfere with or otherwise adversely affect the normal flow of the Service or otherwise act in a manner that may negatively affect other users' experience when using the Service or playing JoyHunter's games. This includes win trading and any other kind of manipulation of rankings, taking advantage of errors in the Service to gain an unfair edge over other players and any other act that intentionally abuses or goes against the design of the Service.
            Disrupt, overburden, or aid or assist in the disruption or overburdening of any computer or server ("Server") used to offer or support the Service or any JoyHunter’s game environment.
        </p>
        <p>
            Institute, assist, or become involved in any type of attack, including without limitation distribution of a virus, denial of service attacks upon the Service, or other attempts to disrupt the Service or any other person's use or enjoyment of the Service.
        </p>
        <p>Attempt to gain unauthorized access to the Service, Accounts registered to others or to the computers, Servers, or networks connected to the Service by any means other than the user interface provided by JoyHunter, including but not limited to, by circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any security, technology, device, or software that is part of the Service. Post any information that is abusive, threatening, obscene, defamatory, libelous, or racially, sexually, religiously, or otherwise objectionable or offensive or engage in ongoing toxic behavior, such as by repeatedly posting information on an unsolicited basis.</p>
        
        <p>Post any information that contains nudity, excessive violence, or offensive subject matter or that contains a link to such content.</p>
        <p>Attempt to, or harass, abuse, or harm, or advocate or incite harassment, abuse, or harm of another person, group, including JoyHunter’s employees, including JoyHunter's customer service representatives.</p>
        <p>Make available through the Service any material or information that infringes any copyright, trademark, patent, trade secret, right of privacy, right of publicity, or other right of any person or entity or impersonates any other person, including without limitation a JoyHunter’s employee.</p>
        <p>Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for any underlying software or other intellectual property used to provide the Service or any JoyHunter‘s game, or to obtain any information from the Service or any JoyHunter’s game using any method not expressly permitted by JoyHunter.</p>
        <p>Solicit or attempt to solicit Login Information or any other login credentials or personal information from other users of the Service or any JoyHunter’s game.</p>
        <p>Collect or post anyone's private information, including personally identifiable information (whether in text, image or video form), identification documents, or financial information through the Service.</p>
        <p>JoyHunter reserves the right to determine what conduct it considers to be in violation of the rules of use or otherwise outside the intent or spirit of these Terms of Service or the Service itself. JoyHunter reserves the right to take action as a result, which may include terminating your Account and prohibiting you from using the Service in whole or in part.</p>
        <p>
            1.2. Suspension and Termination of Account and Service
        </p>
        <p>
            WITHOUT LIMITING ANY OTHER REMEDIES, JOYHUNTER MAY LIMIT, SUSPEND, TERMINATE, MODIFY, OR DELETE ACCOUNTS OR ACCESS TO THE SERVICE OR PORTIONS THEREOF IF YOU ARE, OR JOYHUNTER SUSPECTS THAT YOU ARE, FAILING TO COMPLY WITH ANY OF THESE TERMS OF SERVICE OR FOR ANY ACTUAL OR SUSPECTED ILLEGAL OR IMPROPER USE OF THE SERVICE, WITH OR WITHOUT NOTICE TO YOU. YOU CAN LOSE YOUR USER NAME AND PERSONA IN THE SERVICE AS A RESULT OF ACCOUNT TERMINATION OR LIMITATION, AS WELL AS ANY BENEFITS, PRIVILEGES, EARNED ITEMS AND PURCHASED ITEMS ASSOCIATED WITH YOUR USE OF THE SERVICE, AND JJOYHUNTER IS UNDER NO OBLIGATION TO COMPENSATE YOU FOR ANY SUCH LOSSES OR RESULTS.
        </p>
        <p>
            WITHOUT LIMITING OUR OTHER REMEDIES, WE MAY LIMIT, SUSPEND OR TERMINATE THE SERVICE AND USER ACCOUNTS OR PORTIONS THEREOF, PROHIBIT ACCESS TO OUR GAMES AND SITES, AND THEIR CONTENT, SERVICES AND TOOLS, DELAY OR REMOVE HOSTED CONTENT, AND TAKE TECHNICAL AND LEGAL STEPS TO PREVENT USERS FROM ACCESSING THE SERVICE IF WE BELIEVE THAT THEY ARE CREATING RISK OR POSSIBLE LEGAL LIABILITIES, INFRINGING THE INTELLECTUAL PROPERTY RIGHTS OF THIRD PARTIES, OR ACTING INCONSISTENTLY WITH THE LETTER OR SPIRIT OF OUR TERMS OR POLICIES. ADDITIONALLY, WE MAY, IN APPROPRIATE CIRCUMSTANCES AND AT OUR SOLE DISCRETION, SUSPEND OR TERMINATE ACCOUNTS OF USERS WHO MAY BE REPEAT INFRINGERS OF THIRD PARTY INTELLECTUAL PROPERTY RIGHTS.
        </p>
        <p>
            JOYHUNTER RESERVES THE RIGHT TO TERMINATE ANY ACCOUNT THAT HAS BEEN INACTIVE FOR 180 DAYS.
        </p>

        <p>
            JoyHunter reserves the right to stop offering and/or supporting the Service or a particular game or part of the Service at any time, at which point your license to use the Service or a part thereof will be automatically terminated. In such event, JoyHunter shall not be required to provide refunds, benefits or other compensation to users in connection with such discontinued Service . Termination of your Account can include disabling your access to the Service or any part thereof including any content you submitted or others submitted.
        </p>
        <h5>
            2. Ownership
        </h5>
        <h6>2.1. Games and Service</h6>
        <p>
            All rights, title and interest in and to the Service (including without limitation any games, titles, computer code, themes, objects, characters, character names, stories, dialogue, catch phrases, concepts, artwork, animations, sounds, musical compositions, audio-visual effects, methods of operation, moral rights, documentation, in-game chat transcripts, character profile information, recordings of games played using a JoyHunter game client, and the JoyHunter game clients and server software) are owned by JoyHunter. JoyHunter reserves all rights, including without limitation, all intellectual property rights or other proprietary rights, in connection with its games and the Service.
        </p>
        <h6>
            2.2. Accounts
        </h6>
        <p>
            NOTWITHSTANDING ANYTHING TO THE CONTRARY HEREIN, YOU ACKNOWLEDGE AND AGREE THAT YOU SHALL HAVE NO OWNERSHIP OR OTHER PROPERTY INTEREST IN THE ACCOUNT, AND YOU FURTHER ACKNOWLEDGE AND AGREE THAT ALL RIGHTS IN AND TO THE ACCOUNT ARE AND SHALL FOREVER BE OWNED BY AND INURE TO THE BENEFIT OF JOYHUNTER.
        </p>
        <h6>
            2.3. Virtual Items
        </h6>
        <p>
            JoyHunter owns, has licensed, or otherwise has rights to use all of the content that appears in the Service or in JoyHunter games. Notwithstanding any provision to the contrary herein, you agree that you have no right or title in or to any content that appears in the Service, including without limitation the virtual goods or currency appearing or originating in any JoyHunter game, whether earned in a game or purchased from JoyHunter, or any other attributes associated with an Account or stored on the Service.
        </p>
        <h5>
            3. User Content
        </h5>
        <h6>
            3.1. Submission of User Content
        </h6>
        <p>
            "User Content" means any communications, images, sounds, and all the material, data, and information that you upload or transmit through a JoyHunter game client or the Service, or that other users upload or transmit, including without limitation any chat text. By transmitting or submitting any User Content while using the Service, you affirm, represent and warrant that such transmission or submission is (a) accurate and not confidential or misleading; (b) not in violation of any laws, contractual restrictions or other third party rights, and that you have permission from any third party whose personal information or intellectual property is comprised in the User Content; (c) free of viruses, adware, spyware, worms or other malicious code; and (d) you acknowledge and agree that any of your personal information within such content will at all times be processed by JoyHunter in accordance with its Privacy Policy.
        </p>
        <h6>
            3.1.1. Content Screening
        </h6>
        <p>
            JoyHunter assumes no responsibility for the conduct of any user submitting any User Content, and assumes no responsibility for monitoring the Service for inappropriate content or conduct. We do not, and cannot, pre-screen or monitor all User Content. Your use of the Service is at your own risk. By using the Service, you may be exposed to User Content that is offensive, indecent or otherwise not in line with your expectations. You bear all risks associated with the use of any User Content available in connection with the Service. At our discretion, our representatives or
        </p>
        <p>
            technology may monitor and/or record your interaction with the Service or communications (including without limitation chat text) when you are using the Service.
        </p>
        <p>
            By entering into these Terms of Service, you hereby provide your irrevocable consent to such monitoring and recording. You acknowledge and agree that you have no expectation of privacy concerning the transmission of any User Content, including without limitation chat text or voice communications.
        </p>
        <p>
            JoyHunter reserves the right in its sole discretion to review, monitor, prohibit, edit, delete, disable access to or otherwise make unavailable any User Content (including without limitation your User Content) without notice for any reason or for no reason at any time. If at any time JoyHunter chooses, in its sole discretion, to monitor the Service, JoyHunter nonetheless assumes no responsibility for User Content and assumes no obligation to modify or remove any inappropriate User Content. We have the right, but not the obligation, in our sole discretion to edit, refuse to post, or remove any User Content.
        </p>
        <h5>
            3.2. Information Use by Other Members of the Service
        </h5>
        <h6>
            3.2.1. Public Discourse
        </h6>
        <p>
            The Service may include various forums, blogs and chat features where you can post User Content, including your observations and comments on designated topics. JoyHunter cannot guarantee that other members will not use the ideas and information that you share. Therefore, if you have an idea or information that you would like to keep confidential and/or don't want others to use, do not post it on the Service. JoyHunter shall have no responsibility to evaluate, use or compensate you for any ideas or information you may choose to submit.
        </p>
        <h6>
            3.2.2. Responsible For Your Own Content
        </h6>
        <p>
            You are solely responsible for the information that you post on, through or in connection with the Service and that you provide to others. JoyHunter may reject, refuse to post or delete any User Content for any or no reason, including, but not limited to, User Content that in the sole judgment of JoyHunter violates these Terms of Service.
        </p>
        <h6>
            3.2.3. Your License to JoyHunter
        </h6>
        <p>
            You hereby grant to JoyHunter an irrevocable, perpetual, transferable, fully paid-up, royalty-free, worldwide license (including the right to sublicense and assign to third party) and right to copy, reproduce, fix, adapt, modify, create derivative works from, manufacture, commercialize, publish, distribute, sell, license, sublicense, transfer, lease, transmit, publicly display, publicly perform, or provide access to electronically, broadcast, communicate to the public by telecommunication, display, perform, enter into computer memory, and use and practice, in any way, your User Content as well as all modified and derivative works thereof in connection with our provision of the Service, including marketing and promotions of the Service. You also hereby grant to JoyHunter the right to authorize others to exercise any of the rights granted to JoyHunter under these Terms of Service. You further hereby grant to JoyHunter the unconditional, irrevocable right to use and exploit your name, likeness and any other information or material included in any User Content and in connection with any User Content, without any obligation to you. Except as prohibited by law, you waive any rights of attribution and/or any moral rights you may have in your User Content, regardless of whether your User Content is altered or changed in any manner. JoyHunter does not claim any ownership rights in your User Content and nothing in these Terms of Service is intended to restrict any rights that you may have to use and exploit your User Content. JoyHunter has no obligation to monitor or enforce your intellectual property rights in or to your User Content.
        </p>
        <h6>3.3. User Interactions</h6>
        <p>
            You are solely responsible for your interactions with other users of the Service and any other parties with whom you interact through the Service and/or JoyHunter games. JoyHunter reserves the right, but has no obligation, to become involved in any way with these disputes. You will fully cooperate with JoyHunter to investigate any suspected unlawful, fraudulent or improper activity, including, without limitation, granting JoyHunter access to any password-protected portions of your Account.
        </p>
        <p>
            If you have a dispute with one or more users, you release us (and our officers, directors, agents, subsidiaries, joint ventures and employees) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.
        </p>

        <h5>4. Fees and Purchase Terms</h5>
        <h6>4.1.Purchases</h6>
        <p>
            In the Service you may purchase, with "real world" money, a limited, personal, non-transferable, non-sublicensable, revocable license to use (a) "virtual currency", including but not limited to virtual cash or diamonds, all for use in JoyHunter games; (b) "virtual in-game items" (together with "virtual currency", "Virtual Items"); and (c) other goods or services ("Merchandise"). You are only allowed to purchase Virtual Items from us or our authorised partners through the Service, and not in any other way.
        </p>
        <p>
            JoyHunter may manage, regulate, control, modify or eliminate Virtual Items and/or Merchandise at any time, with or without notice. JoyHunter shall have no liability to you or any third party in the event that JoyHunter exercises any such rights.
        </p>
        <p>
            The transfer of Virtual Items and Merchandise is prohibited except where expressly authorized in the Service. Other than as expressly authorized in the Service, you shall not sell, purchase, redeem or otherwise transfer Virtual Items or Merchandise to any person or entity or attempt any of the aforesaid, including but not limited to JoyHunter, another user or any third party.
        </p>
        <p>
            ALL PURCHASES AND REDEMPTIONS OF VIRTUAL ITEMS MADE THROUGH THE SERVICE ARE FINAL AND NON-REFUNDABLE.
        </p>
        <p>
            The provision of Virtual Items for use in JoyHunter games is a service provided by JoyHunter that commences immediately upon acceptance by JoyHunter of your purchase.
        </p>
        <h6>
            4.2. Payment of Fees
        </h6>
        <p>
            You agree to pay all fees and applicable taxes incurred by you or anyone using an Account registered to you. JoyHunter may revise the pricing for the goods and services offered through the Service at any time. YOU ACKNOWLEDGE THAT JOYHUNTER IS NOT REQUIRED TO PROVIDE A REFUND FOR ANY REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED VIRTUAL ITEMS WHEN AN ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY OR INVOLUNTARY.
        </p>
        <h5>
            5. Updates to the Service
        </h5>
        <p>
            You understand that the Service is an evolving one. JoyHunter may require that you accept updates to the Service and to JoyHunter's games you have installed on your device or computer. You acknowledge and agree that JoyHunter may update the Service and JoyHunter games, with or without notifying you. You may need to update third party software from time to time in order to receive the Service and play JoyHunter games.
        </p>
        <h5>
            6. Disclaimer of Warranties
        </h5>
        <p>
            WITHOUT LIMITING JOYHUNTER'S LIABILITY UNDER SECTION 7 BELOW, THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS FOR YOUR USE, WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND THOSE ARISING FROM COURSE OF DEALING OR USAGE OF TRADE. JOYHUNTER DOES NOT WARRANT THAT YOU WILL BE ABLE TO ACCESS OR USE THE SERVICE AT THE TIMES OR LOCATIONS OF YOUR CHOOSING; THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE; THAT DEFECTS WILL BE CORRECTED; OR THAT THE GAME OR THE SERVICE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. Some jurisdictions do not allow the exclusion of certain warranties. Accordingly, some of the above disclaimers may not apply to you.
        </p>
        <h5>
            7. Limitation of Liability; Sole and Exclusive Remedy; Indemnification
        </h5>
        <p>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, JOYHUNTER SHALL NOT BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR OTHER SIMILAR DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF REVENUES, LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION OR OTHER INTANGIBLE LOSSES (HOWEVER SUCH LOSSES ARE QUALIFIED), ARISING OUT OF OR RELATING IN ANY WAY TO THESE TERMS OF SERVICE OR THE SERVICE ITSELF, WHETHER BASED ON CONTRACT, TORT OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT JOYHUNTER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT NOT PROHIBITED BY LAW, JOYHUNTER SHALL NOT BE LIABLE TO YOU FOR MORE THAN THE AMOUNT YOU HAVE PAID TO JOYHUNTER IN ACCORDANCE WITH THESE TERMS OF SERVICE IN THE SIX (6) MONTHS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST ASSERT A CLAIM. YOU ACKNOWLEDGE AND AGREE THAT IF YOU HAVE NOT PAID ANYTHING TO JOYHUNTER DURING SUCH TIME PERIOD, YOUR SOLE REMEDY (AND JOYHUNTER'S EXCLUSIVE LIABILITY) FOR ANY DISPUTE WITH JOYHUNTER IS TO STOP USING THE SERVICE AND TO CANCEL YOUR ACCOUNT.
        </p>
        <p>
            NOTHING IN THESE TERMS OF SERVICE SHALL AFFECT THE STATUTORY RIGHTS OF ANY CONSUMER OR EXCLUDE OR RESTRICT ANY LIABILITY RESULTING FROM GROSS NEGLIGENCE OR WILLFUL MISCONDUCT OF JOYHUNTER OR FOR DEATH OR PERSONAL INJURY ARISING FROM ANY NEGLIGENCE OR FRAUD OF JOYHUNTER.
        </p>
        <p>
            You agree to indemnify, defend and hold JoyHunter (and our officers, directors, agents, subsidiaries, joint ventures and employees) harmless from any claim, demand, damages or other losses, including reasonable attorneys' fees, asserted by any third-party resulting from or arising out of your use of the Service, or any breach by you of these Terms of Service, however the foregoing does not apply if the infringement of rights is not attributable to your intentional or negligent behavior.
        </p>
        <h5>
            8. Dispute Resolution and Law
        </h5>
        <p>
            If a dispute arises between you and JoyHunter, we strongly encourage you to first contact us directly to seek a resolution. If you are a resident of the United States, these Terms of Service and any dispute arising out of or related to it or Privacy Policy or the Service shall be governed in all respects by California law, without regard to conflict of law provisions. You agree that any claim or dispute you may have against JoyHunter must be resolved exclusively by a court located in San Francisco, California. If you are a resident outside of the United States, you agree that all disputes between you and JoyHunter shall be governed by the laws of Singapore, without regard to conflict of law provisions. You agree that any claim or dispute you may have against JoyHunter must be resolved exclusively by a court located in Singapore.
        </p>
        <h5>
            9. Severability
        </h5>
        <p>
            You and JoyHunter agree that if any portion of these Terms of Service or of the JoyHunter’s Privacy Policy is found illegal or unenforceable, in whole or in part by any court of competent jurisdiction, such provision shall, as to such jurisdiction, be ineffective solely to the extent of such determination of invalidity or unenforceability without affecting the validity or enforceability thereof in any other manner or jurisdiction and without affecting the remaining provisions of the terms, which shall continue to be in full force and effect.
        </p>
        <h5>
            10. General Provisions
        </h5>
        <h6>
            10.1. Assignment
        </h6>
        <p>
            JoyHunter may assign or delegate these Terms of Service and/or the JoyHunter Privacy Policy, in whole or in part, to any person or entity at any time with or without your consent. You may not assign or delegate any rights or obligations under the Terms of Service or Privacy Policy without JoyHunter's prior written consent, and any unauthorized assignment and delegation by you is ineffective.
        </p>
        <h6>
            10.2. Supplemental Policies
        </h6>
        <p>
            JoyHunter may publish additional policies related to specific services such as forums, contests or loyalty programs. Your right to use such services is subject to those specific policies and these Terms of Service.
        </p>
        <h6>
            10.3. Entire Agreement
        </h6>
        <p>
            These Terms of Service, any supplemental policies and any documents expressly incorporated by reference herein (including the JoyHunter’s Privacy Policy), contain the entire understanding of you and JoyHunter, and supersede all prior understandings of the parties hereto relating to the subject matter hereof, whether electronic, oral or written, or whether established by custom, practice, policy or precedent, between you and us with respect to the Service.
        </p>
        <h6>
            10.4. No Waiver
        </h6>
        <p>
            The failure of JoyHunter to require or enforce strict performance by you of any provision of these Terms of Service or the JoyHunter Privacy Policy or failure to exercise any right under them shall not be construed as a waiver or relinquishment of JoyHunter's right to assert or rely upon any such provision or right in that or any other instance.
        </p>
        <p>
            The express waiver by JoyHunter of any provision, condition, or requirement of these Terms of Service or the JoyHunter’s Privacy Policy shall not constitute a waiver of any future obligation to comply with such provision, condition or requirement.
        </p>
        <p>
            Except as expressly and specifically set forth in this these Terms of Service, no representations, statements, consents, waivers, or other acts or omissions by JoyHunter shall be deemed a modification of these Terms of Service nor legally binding, unless documented in physical writing, hand signed by yourself and a duly appointed officer of JoyHunter.
        </p>
        <h6>
            10.5. Notices
        </h6>
        <p>
            We may notify you via postings on <a href="https://joyhunter.fun" target="_blank"></a>, and via e-mail or any other communications means to contact information you provide to us. All notices given by you or required from you under these Terms of Service or the JoyHunter‘s Privacy Policy shall be in writing and addressed to: Han Book Pte. Ltd. Attn: 10 Anson Road #20-05 International Plaza, Singapore, (079903). Any notices that you provide without compliance with this Section on Notices shall have no legal effect.
        </p>
        <h6>
            10.6. Equitable Remedies
        </h6>
        <p>
            You acknowledge that the rights granted and obligations made under these Terms of Service to JoyHunter are of a unique and irreplaceable nature, the loss of which shall irreparably harm JoyHunter and which cannot be replaced by monetary damages alone so that JoyHunter shall be entitled to injunctive or other equitable relief (without the obligations of posting any bond or surety or proof of damages) in the event of any breach or anticipatory breach by you.
        </p>
        <p>
            You irrevocably waive all rights to seek injunctive or other equitable relief, or to enjoin or restrain the operation of the Service or any JoyHunter game, exploitation of any advertising or other materials issued in connection therewith, or exploitation of the Service or any content or other material used or displayed through the Service and agree to limit your claims to claims for monetary damages, limited by Section 7 (if any).
        </p>
        <h6>
            10.7. Force Majeure
        </h6>
        <p>
            JoyHunter shall not be liable for any delay or failure to perform resulting from causes outside the reasonable control of JoyHunter, including without limitation any failure to perform hereunder due to unforeseen circumstances or cause beyond JoyHunter's control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, strikes, or shortages of transportation facilities, fuel, energy, labor or materials.
        </p>
        </b-container>
    </div>
</template>
<script>
export default {
    data(){
        return{}
    },
    created(){
        
    }
}
</script>
<style lang="scss" scoped>
    h1{
        text-align: center !important;
        margin: 1.25rem 0;
    }
    h5,h6{
        margin-bottom: 1.25rem;
    }
    ul li{
        list-style: disc;
    }
</style>